import React, { useEffect, useContext } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { SummaryContext } from 'tracking/template/summary.context';

import { ReactComponent as TruckImage } from 'images/illustra-packages.svg';

import WELCOME_CONST from './welcome.constants';

function WelcomeContainer() {
  const { setSummary } = useContext(SummaryContext);
  useEffect(() => {
    setSummary(
      <>
        <Box mb={10}>
          <Typography variant="h3">
            <strong>{WELCOME_CONST.GREETINGS}</strong>
          </Typography>
        </Box>
        <Box mb={4}>
          <TruckImage />
        </Box>
        <Grid container>
          <Grid item xs={6}>
            <Box mb={2}>
              <Typography variant="h4">{WELCOME_CONST.TEXT}</Typography>
            </Box>
            <Box mb={10}>
              <Typography variant="h4">{WELCOME_CONST.CONTACT}</Typography>
            </Box>
          </Grid>
        </Grid>
      </>
    );
  }, [setSummary]);

  return null;
}

export default WelcomeContainer;
