import React from 'react';

import AlertStatusComponent from '@loggi/components/src/arco/alert-status/alert-status.component';

import ErrorIcon from '@material-ui/icons/Error';

import { isAuthenticated } from 'infra/service/login';
import { LOGGED_WITH_LMA } from './alert.constants';

export default function AlertLMADeprecated() {
  return (
    <>
      {isAuthenticated() && (
        <AlertStatusComponent
          colors="alertColorsLoggedWithLma"
          customIcon={<ErrorIcon color="error" />}
        >
          {LOGGED_WITH_LMA}
        </AlertStatusComponent>
      )}
    </>
  );
}
