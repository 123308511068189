import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Chip, Divider, ListItem } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Typography from '@material-ui/core/Typography';
import { CustomDrawer, DrawerHeader, DrawerContent } from 'shared/drawer';
import { IsFSActiveForCurrentBase } from 'auth/access-control';
import useStylesDrawer from 'view/molecules/package-drawer/drawer.style';
import ConfirmDialogComponent from 'shared/confirm-dialog';
import { SWITCHES } from 'view/constants';
import TEXT from './constants';
import DriverInfoContainer from './allocated/DriverInfo.container';
import { CANCEL_DIALOG } from './allocated/constans';
import CancelAllocatedContainer from './allocated/CancelAllocated.container';

function HeaderButton({ loading, setOpenHeaderActionConfirmationDialog }) {
  return (
    <Button
      variant="outlined"
      color="primary"
      size="medium"
      data-testid="open-disassociation-dialog"
      disabled={loading}
      onClick={() => setOpenHeaderActionConfirmationDialog(true)}
    >
      {CANCEL_DIALOG.UNDO_DISTRIBUTION}
    </Button>
  );
}

HeaderButton.propTypes = {
  setOpenHeaderActionConfirmationDialog: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired
};

const ListItemComponent = ({ label, onClick, loading, classes }) => (
  <ListItem disabled={loading} disableGutters button onClick={onClick}>
    <Box
      my={2.5}
      px={2.5}
      display="flex"
      flexGrow={1}
      alignItems="center"
      justifyContent="space-between"
    >
      <Typography variant="body1">{label}</Typography>
      <ArrowForwardIosIcon
        className={classes.fontSizeIcon}
        color="primary"
        size="small"
      />
    </Box>
  </ListItem>
);

ListItemComponent.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  classes: PropTypes.shape().isRequired
};

export default function DrawerDetailGroupComponent({
  onCloseDrawer,
  dataGroup,
  handleViewCirclesDrawer,
  handleViewDeliverersDrawer,
  handleHeaderClick,
  loadingDisassociation,
  handleViewEditGroup,
  handleViewEditKeywords,
  infoDriverAllocated,
  fsRealtimeEnableFetchGroupItIsAllocatedInDrawer
}) {
  const classes = useStylesDrawer();
  const [loading, setLoading] = useState(false);
  const [
    openHeaderActionConfirmationDialog,
    setOpenHeaderActionConfirmationDialog
  ] = useState(false);

  const enableGroupEditing = IsFSActiveForCurrentBase(
    SWITCHES.enableGroupEditing
  );

  const circleButtonText = dataGroup.circleName
    ? TEXT.EDIT_CIRCLE
    : TEXT.DIRECT_CIRCLE;

  const handleDialog = () => {
    setOpenHeaderActionConfirmationDialog(false);
    handleHeaderClick();
  };

  useEffect(() => {
    setLoading(loadingDisassociation);
  }, [loadingDisassociation]);

  return (
    <>
      <CustomDrawer open>
        <DrawerHeader showDivider={false} handleClosingDrawer={onCloseDrawer}>
          <Typography variant="body2" className={classes.smoke}>
            {TEXT.GROUP_PACKAGES}
          </Typography>

          <Typography variant="h4" className={classes.bolder}>
            {`${dataGroup.licensePlate} ${TEXT.IN_BASE}`}
          </Typography>

          <Typography variant="h5">{`${dataGroup.quantityPackages} ${
            TEXT.PACKAGES_ADDED
          }`}</Typography>

          {dataGroup.circleName && (
            <Box mt={2.5}>
              <Typography
                variant="subtitle2"
                className={classes.item}
                data-testid="status-message"
              >
                {`${TEXT.TO} ${dataGroup.circleName}`}
              </Typography>
            </Box>
          )}
          {// eslint-disable-next-line
          infoDriverAllocated?.is_allocated && (
            <Box mt={2}>
              <CancelAllocatedContainer
                onCloseDrawer={onCloseDrawer}
                dataGroup={dataGroup}
              />
            </Box>
          )}

          {dataGroup.keywords.length > 0 && (
            <Box mt={2} display="flex" flexWrap="wrap">
              {dataGroup.keywords.map(keyword => (
                <Chip
                  key={keyword}
                  label={
                    <Typography color="textSecondary" variant="body1">
                      <em>{keyword}</em>
                    </Typography>
                  }
                  className={classes.chip}
                  size="small"
                />
              ))}
            </Box>
          )}
        </DrawerHeader>
        <DrawerContent lessPadding>
          {// eslint-disable-next-line
          infoDriverAllocated?.is_allocated && (
            <>
              <Divider />
              <DriverInfoContainer infoDriverAllocated={infoDriverAllocated} />
            </>
          )}
          <Divider />
          <ListItemComponent
            label={circleButtonText}
            onClick={handleViewCirclesDrawer}
            loading={loading}
            classes={classes}
          />
          <Divider />
          {!infoDriverAllocated.is_allocated && (
            <ListItemComponent
              label={TEXT.ASSOCIATE_DELIVERER}
              onClick={handleViewDeliverersDrawer}
              loading={loading}
              classes={classes}
            />
          )}
          <Divider />
          {enableGroupEditing &&
            !dataGroup.circleName &&
            // eslint-disable-next-line
            !fsRealtimeEnableFetchGroupItIsAllocatedInDrawer && (
              <>
                <ListItemComponent
                  label={TEXT.EDIT_GROUP}
                  onClick={handleViewEditGroup}
                  loading={loading}
                  classes={classes}
                />
                <Divider />
              </>
            )}
          {enableGroupEditing &&
            !dataGroup.circleName &&
            // eslint-disable-next-line
            fsRealtimeEnableFetchGroupItIsAllocatedInDrawer &&
            !infoDriverAllocated.is_allocated && (
              <>
                <ListItemComponent
                  label={TEXT.EDIT_GROUP}
                  onClick={handleViewEditGroup}
                  loading={loading}
                  classes={classes}
                />
                <Divider />
              </>
            )}

          <ListItemComponent
            label={TEXT.EDIT_KEYWORDS}
            onClick={handleViewEditKeywords}
            loading={loading}
            classes={classes}
          />

          <Divider />
        </DrawerContent>
      </CustomDrawer>
      {openHeaderActionConfirmationDialog && (
        <ConfirmDialogComponent
          open
          title={TEXT.DISASSOCIATION_CONFIRM_TITLE}
          description={TEXT.DISASSOCIATION_CONFIRM_SUBTITLE}
        >
          <Box
            display="flex"
            flexDirection={{ xs: 'column', sm: 'row-reverse' }}
          >
            <Box>
              <Button
                fullWidth
                data-testid="dialog-confirm"
                size="large"
                variant="contained"
                color="primary"
                onClick={handleDialog}
              >
                {TEXT.DISASSOCIATION_CONFIRM_BUTTON}
              </Button>
            </Box>
            <Box pt={{ xs: 2.5, sm: 0 }} mr={{ xs: 0, sm: 1 }}>
              <Button
                fullWidth
                data-testid="dialog-back"
                size="large"
                variant="outlined"
                color="primary"
                onClick={() => setOpenHeaderActionConfirmationDialog(false)}
              >
                {TEXT.DISASSOCIATION_CANCEL_BUTTON}
              </Button>
            </Box>
          </Box>
        </ConfirmDialogComponent>
      )}
    </>
  );
}

DrawerDetailGroupComponent.propTypes = {
  onCloseDrawer: PropTypes.func.isRequired,
  handleViewCirclesDrawer: PropTypes.func.isRequired,
  handleViewDeliverersDrawer: PropTypes.func.isRequired,
  dataGroup: PropTypes.shape({
    licensePlate: PropTypes.string,
    quantityPackages: PropTypes.number,
    circleName: PropTypes.string,
    circleId: PropTypes.string,
    keywords: PropTypes.arrayOf(PropTypes.string)
  }).isRequired,
  handleHeaderClick: PropTypes.func.isRequired,
  loadingDisassociation: PropTypes.bool.isRequired,
  handleViewEditGroup: PropTypes.func.isRequired,
  handleViewEditKeywords: PropTypes.func.isRequired,
  infoDriverAllocated: PropTypes.shape().isRequired,
  fsRealtimeEnableFetchGroupItIsAllocatedInDrawer: PropTypes.bool
};

DrawerDetailGroupComponent.defaultProps = {
  fsRealtimeEnableFetchGroupItIsAllocatedInDrawer: false
};
