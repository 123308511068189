import { getSelectedRoutingCode } from 'auth/login/login.service';
import {
  PACKAGE_STATUS_CODE,
  RETURN_TO_BASE_CONTEXT,
  PACKAGE_RECEIVED,
  GOING_TO_BASE_CONTEXT
} from '../drawer.constants';

const IN_BASE = {
  changeStatus: true,
  downloadCTE: false,
  refusePackage: true,
  returnPackageToBase: false,
  changeDeliverer: true,
  changeAddress: true
};

const RETURN_TO_LOGGI = {
  changeStatus: false,
  downloadCTE: true,
  refusePackage: false,
  returnPackageToBase: false,
  changeDeliverer: false,
  changeAddress: false
};

const RETURN_TO_LEVE = {
  changeStatus: true,
  downloadCTE: false,
  refusePackage: false,
  returnPackageToBase: false,
  changeDeliverer: true,
  changeAddress: true
};

const AT_STREET = {
  changeStatus: true,
  downloadCTE: false,
  refusePackage: false,
  returnPackageToBase: true,
  changeDeliverer: true,
  changeAddress: false
};

const DELIVERED = {
  changeStatus: true,
  downloadCTE: false,
  refusePackage: false,
  returnPackageToBase: false,
  changeDeliverer: false,
  changeAddress: false
};

const AT_BASE_WITH_LESS_ACTIONS = {
  changeStatus: false,
  downloadCTE: false,
  refusePackage: false,
  returnPackageToBase: false,
  changeDeliverer: false,
  changeAddress: true
};

const AT_BASE_WITH_CHANGE_DELIVERER = {
  changeStatus: false,
  downloadCTE: false,
  refusePackage: false,
  returnPackageToBase: false,
  changeDeliverer: true,
  changeAddress: true
};

const GOING_TO_BASE = {
  changeStatus: false,
  downloadCTE: false,
  refusePackage: false,
  returnPackageToBase: false,
  changeDeliverer: false,
  changeAddress: true
};

// This configuration is temporary since Leves and Agencies
// will share contexts (such as REC).
const DEFAULT = {
  changeStatus: false,
  downloadCTE: false,
  refusePackage: false,
  returnPackageToBase: false,
  changeDeliverer: false,
  changeAddress: false
};

export const contextMapper = licensePlate => {
  const CONTEXTS = [
    'REC',
    'DEVO',
    'CARRO',
    'COL',
    'ALT',
    'MOTO',
    'PRE',
    'VAN',
    'DELIVERY',
    'DELIVERED',
    'SEP',
    'REFUSED',
    'DIS'
  ];
  const context = licensePlate.split(' ');

  const found = context.find(elem => CONTEXTS.includes(elem));

  if (found) {
    return found;
  }

  return 'DEFAULT';
};

const getPackageRoutingCodesFromLastMileAnnotation = packageInfo => {
  const lastMileAnnotationChoices = packageInfo?.lastMileAnnotation?.choices;
  const lastMileCompanies = lastMileAnnotationChoices?.map(
    choice => choice.lastMileCompany
  );
  return lastMileCompanies?.map(lastMileCompany => lastMileCompany.routingCode);
};

const getPackageRoutingCodeFromSortingContext = packageInfo => {
  // eslint-disable-next-line camelcase
  const { license_plate } = packageInfo?.sortingContext || {
    license_plate: ''
  };

  const licensePlateSplitted = license_plate.split(' ').filter(Boolean);

  return licensePlateSplitted.length > 0
    ? licensePlateSplitted.slice(0, 1)[0]
    : '';
};

const getContextFromPackageGoingToBase = packageInfo => {
  const routingCodeBase = getSelectedRoutingCode();

  const packageRoutingCodeFromSortingContext = getPackageRoutingCodeFromSortingContext(
    packageInfo
  );

  if (packageRoutingCodeFromSortingContext !== routingCodeBase) {
    const packageRoutingCodesFromLastMileAnnotation = getPackageRoutingCodesFromLastMileAnnotation(
      packageInfo
    );

    if (packageRoutingCodesFromLastMileAnnotation?.includes(routingCodeBase)) {
      return GOING_TO_BASE_CONTEXT;
    }
  }

  return null;
};

export const getProcessDestinationOrContext = packageInfo => {
  // eslint-disable-next-line camelcase
  const { license_plate } = packageInfo?.sortingContext ||
    packageInfo?.unitLoad || { license_plate: '' };

  if (license_plate.includes(':')) {
    return license_plate
      .split(':')
      .slice(-2)
      .join(':');
  }

  const contextPackageGoingToBase = getContextFromPackageGoingToBase(
    packageInfo
  );

  if (contextPackageGoingToBase !== null) {
    return contextPackageGoingToBase;
  }

  const context = contextMapper(license_plate);

  if (
    context === RETURN_TO_BASE_CONTEXT &&
    packageInfo?.status?.code === PACKAGE_STATUS_CODE.TRANSFER_COMPLETED
  ) {
    return PACKAGE_RECEIVED;
  }
  return context;
};

function listExhibition(packageInfo) {
  const processDestinationOrContext = getProcessDestinationOrContext(
    packageInfo
  );

  return (
    {
      'receiving:rejected': RETURN_TO_LOGGI,
      'receiving:accepted': IN_BASE,
      'return:leve': RETURN_TO_LEVE,
      'return:loggi': RETURN_TO_LOGGI,
      'delivery:in_progress': AT_STREET,
      'delivery:recipient_refused': AT_STREET,
      'delivery:invalid_destination': AT_STREET,
      'delivery:recipient_unavailable': AT_STREET,
      'delivery:delivered': DELIVERED,
      REC: IN_BASE,
      COL: RETURN_TO_LEVE,
      ALT: AT_BASE_WITH_LESS_ACTIONS,
      PRE: AT_BASE_WITH_LESS_ACTIONS,
      REFUSED: RETURN_TO_LOGGI,
      DEVO: AT_BASE_WITH_LESS_ACTIONS,
      CARRO: AT_BASE_WITH_LESS_ACTIONS,
      MOTO: AT_BASE_WITH_LESS_ACTIONS,
      VAN: AT_BASE_WITH_LESS_ACTIONS,
      SEP: AT_BASE_WITH_CHANGE_DELIVERER,
      DELIVERY: AT_STREET,
      DELIVERED,
      GOING_TO_BASE,
      DIS: RETURN_TO_LOGGI
    }[processDestinationOrContext] || DEFAULT
  );
}

export default listExhibition;
