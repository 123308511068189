import * as Sentry from '@sentry/browser';

import api from 'infra/api/leve';
import { removeSelectedBase } from 'profile/profile.service';
import * as storage from '../storage/credential';

function parseJwt(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(c => {
        const hex = `00${c.charCodeAt(0).toString(16)}`;
        return `%${hex.slice(-2)}`;
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
}

export const login = async ({ username, password }) => {
  // we access two external resources for authentication
  // both Leve's API and browser's localStorage
  const response = await api.post('/login', { username, password });

  const { data } = response;
  storage.storeCredentials(data);
  return response;
};

export const logout = () => {
  storage.removeCredentials();
  removeSelectedBase();
  Sentry.setUser({});
};

export const getCredentials = () => storage.getCredentials();

export const isAuthenticated = () =>
  Object.entries(getCredentials()).length > 0;

export const getLoggedUser = () => getCredentials().username;

export const getUser = () => {
  const cred = getCredentials();
  const { access_token: accessToken } = cred;
  if (accessToken) {
    const decoded = parseJwt(accessToken);
    return decoded;
  }
  return null;
};

export const getLoggedDCId = () => {
  return getUser()?.distributionsCenter[0];
};

export const getLoggedLMCId = () => {
  return getUser()?.lastMileCompanyId;
};
