import { Machine, sendParent } from 'xstate';
import { getSelectedRoutingCode } from 'auth/login/login.service';
import { drawerMovePackages } from '../drawer-move-package.service';
import { URL_GTM_GA_RETURN_TO_BASE_DONE } from './drawer-return-package-to-base.constants';
import { RETURN_TO_BASE_CONTEXT } from '../drawer.constants';

export const STATES = {
  SUBMITTING: 'submittingMovePackage',
  CONFIRM_DIALOG: 'showConfirmDialog',
  FINAL: 'final'
};

export const ACTIONS = {
  RELOAD_PAGE: 'reloadPage',
  BACK: 'backToDetail',
  SET_NOTIFICATION: 'setNotification',
  GOOGLE_ANALITYCS: 'googleAnalitycs'
};

const returnPackageToBaseMachine = Machine({
  id: 'returnPackageToBase',
  initial: STATES.SUBMITTING,
  context: {
    packageBarcode: ''
  },
  states: {
    [STATES.SUBMITTING]: {
      invoke: {
        id: 'invokeReturnPackageToBase',
        src: context => {
          return drawerMovePackages({
            packageBarcode: context.packageBarcode,
            sortingContextLpn: `${getSelectedRoutingCode()} ${RETURN_TO_BASE_CONTEXT}`
          });
        },
        onDone: {
          target: [STATES.CONFIRM_DIALOG]
        },
        onError: {
          target: STATES.FINAL,
          actions: [
            sendParent((_, event) => ({
              type: ACTIONS.SET_NOTIFICATION,
              data: {
                message: event.data.message,
                error: true
              },
              to: 'drawer'
            }))
          ]
        }
      }
    },
    [STATES.CONFIRM_DIALOG]: {
      entry: sendParent(() => ({
        type: ACTIONS.GOOGLE_ANALITYCS,
        data: {
          url: URL_GTM_GA_RETURN_TO_BASE_DONE
        },
        to: 'drawer'
      })),
      on: {
        [ACTIONS.RELOAD_PAGE]: {
          actions: sendParent(ACTIONS.RELOAD_PAGE, { to: 'drawer' })
        }
      }
    },
    [STATES.FINAL]: {
      entry: sendParent(ACTIONS.BACK, { to: 'drawer' }),
      type: 'final'
    }
  }
});
export default returnPackageToBaseMachine;
