import React, { useCallback, useState, useEffect, useRef } from 'react';
import { getCompanyType } from 'auth/access-control/access-control.service';
import PropTypes from 'prop-types';
import { useParams, useLocation } from 'react-router-dom';
import moment from 'moment';
import clsx from 'clsx';
import { AUTHORIZED_ROUTES } from 'view/constants';
import { Box, Grid, Paper, Typography } from '@material-ui/core';

import { Skeleton } from '@material-ui/lab';

import { ReactComponent as ICBase } from 'images/ic-base.svg';

import { useStateValue } from 'shared/contexts';

import FilterComponent from 'shared/filter';
import { useStyles as useStylesIcon } from 'shared/card-icon/card-icon.styles';

import Pagination, {
  usePagination,
  ITEMS_PER_PAGE_THIRTY,
  ITEMS_PER_PAGE_TEN
} from 'shared/pagination';
import SelectComponent from 'shared/select';
import { ORDER_BY, INTERNAL_LABEL } from 'shared/select/select.constants';
import { IsFSActiveForCurrentBase } from 'auth/access-control';
import { URL_GTM_GA_CHANGE_DELIVERER_DONE } from 'view/molecules/package-drawer/drawer-change-deliverer/drawer-change-deliverer.constants';
import { URL_GTM_GA_RETURN_TO_BASE_DONE } from 'view/molecules/package-drawer/drawer-return-package-to-base/drawer-return-package-to-base.constants';

import AlertStatusHeader, {
  isPlatformStatusDegraded
} from 'shared/alert-status-header';
import ConfigurableTable from 'shared/configurable-table';
import { FILTERS } from 'shared/filter/filter.constants';
import CardAggregationContainer from './card-aggregation';
import ConfigurableTableCollapsible from './configurable-table-collapsible';
import Template, { Content, Summary } from './template';
import { CardReceivePackageContainer } from './card-receive-package';
import { tratementDateToElastic } from './date-range-picker';
import { TIME } from './date-range-picker/date-range-picker.constants';
import AsyncPackages from './async-packages';

import { useStyles } from './tracking.styles';
import trackingService, {
  fetchGroupByUnitLoad,
  generateCsvLink
} from './tracking.service';
import CUSTODY_MAP, {
  getLayout,
  CONFIGURABLE_TABLE_CONFIGURATION_NA_BASE_WITH_DELIVERY_DEAD_LINE_NEW,
  CONFIGURABLE_TABLE_COLLAPSIBLE_CONFIGURATION_NA_BASE_WITH_DELIVERY_DEAD_LINE_NEW,
  CONFIGURABLE_TABLE_COLLAPSIBLE_CHILDREN_CONFIGURATION_NA_BASE_WITH_DELIVERY_DEAD_LINE_NEW,
  deliveryDeadlineNew,
  promisedDateULNew,
  amountULSortNew
} from './tracking.configuration';
import {
  KEY_CUSTODY,
  TOGGLE,
  TEXT_ALERT_STATUS_HEADER
} from './tracking.constants';
import {
  DateRangePickerUtilStartDate,
  DateRangePickerUtilEndDate
} from './date-range-picker/date-range-picker.util';
import TrackingSummaryComponent from './tracking.component.summary';

const getFilterBtnTitle = arrayFilter => {
  return arrayFilter === FILTERS.DELIVERER_INCLUDING_INACTIVE
    ? FILTERS.DELIVERER
    : arrayFilter;
};

export default function TrackingContainer({ withLargeUnitload }) {
  const [count, setCount] = useState(0);
  const [loadingTable, setLoadingTable] = useState(false);
  const [loading, setLoading] = useState(true);
  const [packages, setPackages] = useState([]);
  const [filters, setFilters] = useState({});
  const [
    pagination,
    changePage,
    setNumberOfItems,
    resetPagination,
    setCurrentPage
  ] = usePagination();
  const [error, setError] = useState('');
  const [startDate, setStartDate] = useState(
    tratementDateToElastic(moment().subtract(60, 'days'), TIME.START_DAY)
  );
  const [endDate, setEndDate] = useState(
    tratementDateToElastic(moment(), TIME.END_DAY)
  );
  const [downloadCsvLink, setDownloadCsvLink] = useState('');
  const [, dispatch] = useStateValue();

  const { pathname } = useLocation();

  if (pathname.split('/').indexOf('sucesso') !== -1) {
    const url = pathname.includes(URL_GTM_GA_CHANGE_DELIVERER_DONE)
      ? `${pathname.replace(URL_GTM_GA_CHANGE_DELIVERER_DONE, '')}`
      : `${pathname.replace(URL_GTM_GA_RETURN_TO_BASE_DONE, '')}`;
    window.history.pushState(null, '', url);
  }

  const handleAlignment = (event, newAlignment) => {
    if (newAlignment !== null) {
      let url;
      if (newAlignment === TOGGLE.VALUE_GROUPS) {
        url = `${pathname}${AUTHORIZED_ROUTES.TRACKING.IN_GROUPS}`;
      } else {
        url = `${pathname.replace(AUTHORIZED_ROUTES.TRACKING.IN_GROUPS, '')}`;
      }
      window.history.pushState(null, '', url);
      window.location.reload();
    }
  };

  const type = 'setLoading';
  const payload = load => setLoading(load);

  const previousRef = useRef();

  let { custody } = useParams();
  const { argument, baseView } = useParams();
  if (custody === KEY_CUSTODY.NA_BASE && baseView)
    custody = `${custody}/${baseView}`;

  const renderState = getLayout(custody);
  const content = CUSTODY_MAP[custody];
  const classes = useStyles();

  const classesIcon = useStylesIcon();

  const { orderBy: orderByList } = CUSTODY_MAP[renderState];

  const enableFilterPackageSuspiciousLocation2 = IsFSActiveForCurrentBase(
    'enableFilterPackageSuspiciousLocation2'
  );

  const hasFilterCitiesEnabled = getCompanyType() === 'LEVE';

  const enableCreateAwaitingResolutionPromisedDateInBaseTable =
    getCompanyType() === 'LEVE';

  const hasCirclesEnabled = getCompanyType() === 'LEVE';

  const enableAwaitingResolutionInNaBase =
    enableCreateAwaitingResolutionPromisedDateInBaseTable &&
    custody === KEY_CUSTODY.NA_BASE_AGUARDANDO_TRATATIVA;

  if (enableAwaitingResolutionInNaBase) {
    orderByList.default[0] = deliveryDeadlineNew;
    orderByList.ul[0] = promisedDateULNew;
    orderByList.ul[1] = amountULSortNew;
  }

  const lastMileCompanyType = getCompanyType();

  const orderByChoices =
    withLargeUnitload && orderByList.ul ? orderByList.ul : orderByList.default;

  const [orderBy, setOrderBy] = useState(orderByChoices[0]);

  const onError = responseError => {
    setError(responseError.message);
    setLoading(false);
  };

  // handle function to be used when expanding a
  const handleUnitLoadClick = (licensePlate, page) => {
    return trackingService({
      custody: renderState,
      page,
      itemsPerPage: ITEMS_PER_PAGE_TEN,
      orderBy: orderByChoices[0].sortBy,
      orderDirection: orderByChoices[0].orderDirection,
      filters: {
        ...filters,
        unitLoad: 'LICENSE_PLATE',
        licensePlate
      }
    }).then(cleanData => cleanData.hits);
  };

  const handleTrackingService = useCallback(() => {
    if (withLargeUnitload) {
      return fetchGroupByUnitLoad(
        renderState,
        pagination.currentPage,
        ITEMS_PER_PAGE_THIRTY,
        orderBy.sortBy,
        orderBy.secondOrderBy,
        filters,
        custody === KEY_CUSTODY.NA_RUA ? 'user_name' : 'unit_load'
      )
        .then(cleanData => {
          setCount(cleanData.totalPackages);
          setNumberOfItems(cleanData.totalUnitLoads);
          setPackages(cleanData.data);
          setLoading(false);
        })
        .catch(onError);
    }
    return trackingService({
      custody: renderState,
      page: pagination.currentPage,
      itemsPerPage: ITEMS_PER_PAGE_THIRTY,
      orderBy: orderBy.sortBy,
      orderDirection: orderBy.orderDirection,
      filters,
      searchArgument: argument,
      startDate: content.isDatePickerDisplayed
        ? DateRangePickerUtilStartDate(startDate)
        : null,
      endDate: content.isDatePickerDisplayed
        ? DateRangePickerUtilEndDate(endDate)
        : null,
      lastMileCompanyType
    })
      .then(cleanData => {
        setCount(cleanData.hits.total.value);
        setNumberOfItems(cleanData.hits.total.value);
        setPackages(cleanData.hits.hits);
        setLoading(false);
      })
      .catch(onError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    argument,
    filters,
    orderBy.orderDirection,
    orderBy.sortBy,
    pagination.currentPage,
    ITEMS_PER_PAGE_THIRTY,
    renderState,
    startDate,
    endDate
  ]);

  const handleReset = useCallback(() => {
    resetPagination();
    setLoading(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeDate = (startDateParam = null, endDateParam = null) => {
    setStartDate(startDateParam);
    setEndDate(endDateParam);
  };

  useEffect(() => {
    handleReset();
  }, [startDate, endDate, handleReset]);

  useEffect(() => {
    // this useEffect is used to detect custody change
    // without it, the fetch useEffect wont be triggered correctly
    if (previousRef.current && previousRef.current !== renderState) {
      setCount(null);
      setFilters({});
      setEndDate(null);
      setStartDate(null);
      setOrderBy(orderByChoices[0]);
      handleReset();
    }
  }, [renderState, handleReset, orderByChoices]);

  useEffect(() => {
    // this useEffect handles changes of argument
    setOrderBy(orderByChoices[0]);
    handleReset();
  }, [argument, handleReset, renderState, orderByChoices]);

  useEffect(() => {
    if (loading) {
      previousRef.current = renderState;
      handleTrackingService();
    }

    dispatch({
      type,
      payload
    });
  }, [loading, renderState, handleTrackingService, dispatch]);

  // if there is packages or some filter were appied, COULD have package.
  const hasPackages =
    count > 0 ||
    Object.values(filters).reduce((a, b) => a.concat(b), []).length > 0;

  const onOrderChange = item => {
    setOrderBy(item);
    handleReset();
  };

  const onApplyChanges = items => {
    if (items.remove) {
      setFilters(current => {
        const currentState = current;
        delete currentState[items.remove];
        return { ...currentState };
      });
    } else {
      setFilters(currentState => {
        return { ...currentState, ...items };
      });
    }

    handleReset();
  };

  const changePagination = page => {
    changePage(page);
    setLoading(true);
  };

  const onBarCodeRead = loadingBarCode => {
    setLoadingTable(loadingBarCode);
  };

  useEffect(() => {
    (async () => {
      const csvLink = await generateCsvLink();
      setDownloadCsvLink(csvLink);
    })();
  }, []);

  return (
    <Template>
      <Summary>
        <TrackingSummaryComponent
          loading={loading}
          custody={custody}
          count={count}
          searchArgument={argument}
          hasPackages={hasPackages}
          error={error}
          downloadCsvLink={downloadCsvLink}
          changeDate={changeDate}
          startDate={startDate}
          endDate={endDate}
          handleAlignment={handleAlignment}
          withLargeUnitload={withLargeUnitload}
        />
      </Summary>

      <Content>
        {!loading && <AsyncPackages />}

        {hasPackages && (
          <CardAggregationContainer
            filters={filters}
            date={[
              content.isDatePickerDisplayed
                ? DateRangePickerUtilStartDate(startDate)
                : null,
              content.isDatePickerDisplayed
                ? DateRangePickerUtilEndDate(endDate)
                : null
            ]}
            externalLoading={loading}
          />
        )}
        {renderState === KEY_CUSTODY.NA_BASE && (
          <CardReceivePackageContainer
            onBarCodeRead={onBarCodeRead}
            filters={filters}
            externalLoading={loading}
          />
        )}

        {!hasPackages && renderState === KEY_CUSTODY.NA_BASE && (
          <Grid
            container
            spacing={6}
            justify="center"
            alignItems="center"
            data-testid="empty-content"
          >
            <Grid item xs={5}>
              <Box
                className={clsx(
                  classesIcon.iconStyle,
                  classesIcon.variantsecondary,
                  classesIcon.sizelarge
                )}
                mx="auto"
                my={5}
              >
                <ICBase data-testid="ic-base-empty" />
              </Box>
              <Typography color="textSecondary" align="center">
                {CUSTODY_MAP[renderState].emptyDetail}
              </Typography>
            </Grid>
          </Grid>
        )}
        {hasPackages && (
          <>
            <Paper
              className={classes.paper}
              component={Box}
              elevation={10}
              flexGrow={1}
              marginBottom={3}
            >
              <AlertStatusHeader
                textPrimary={TEXT_ALERT_STATUS_HEADER.LIST_PACKAGES.PRIMARY}
                textSecondary={TEXT_ALERT_STATUS_HEADER.LIST_PACKAGES.SECONDARY}
              />
              <Box p={5.5} minHeight={44}>
                <Box mb={2} display="flex" justifyContent="space-between">
                  <Box>
                    {/* loading state wont re-render Filter and OrderBy components, */}
                    {/* because we need to preserve previous state/selected items */}
                    {CUSTODY_MAP[renderState].arrayFilters.map(item => {
                      if (
                        !enableFilterPackageSuspiciousLocation2 &&
                        item === FILTERS.ALERTS
                      ) {
                        return null;
                      }

                      if (!hasFilterCitiesEnabled && item === FILTERS.CITIES) {
                        return null;
                      }

                      if (!hasCirclesEnabled && item === FILTERS.CIRCLES)
                        return null;

                      return (
                        <FilterComponent
                          key={item}
                          btnFilter={getFilterBtnTitle(item)}
                          onApplyChanges={onApplyChanges}
                          whichFilter={item}
                          status={CUSTODY_MAP[renderState].status}
                          startDate={startDate}
                          endDate={endDate}
                        />
                      );
                    })}
                  </Box>
                  <SelectComponent
                    onSelectedChange={onOrderChange}
                    selected={orderBy}
                    selectOptions={orderByChoices}
                    prefixLabel={ORDER_BY}
                    internalOptionsLabel={INTERNAL_LABEL}
                  />
                </Box>
                {!loading && !loadingTable && withLargeUnitload && (
                  <ConfigurableTableCollapsible
                    data={packages}
                    columns={
                      enableAwaitingResolutionInNaBase
                        ? CONFIGURABLE_TABLE_COLLAPSIBLE_CONFIGURATION_NA_BASE_WITH_DELIVERY_DEAD_LINE_NEW
                        : content.tableConfigurationCollapsible
                    }
                    columnsChildren={
                      enableAwaitingResolutionInNaBase
                        ? CONFIGURABLE_TABLE_COLLAPSIBLE_CHILDREN_CONFIGURATION_NA_BASE_WITH_DELIVERY_DEAD_LINE_NEW
                        : content.tableConfigurationCollapsibleChildren
                    }
                    callbackNested={handleUnitLoadClick}
                    itensPerPageCollapsible={ITEMS_PER_PAGE_TEN}
                  />
                )}
                {!loading && !loadingTable && !withLargeUnitload && (
                  <ConfigurableTable
                    data={packages}
                    columns={
                      enableAwaitingResolutionInNaBase
                        ? CONFIGURABLE_TABLE_CONFIGURATION_NA_BASE_WITH_DELIVERY_DEAD_LINE_NEW
                        : content.tableConfiguration
                    }
                    withPackageDrawer
                    systemUnstable={isPlatformStatusDegraded()}
                  />
                )}
                {(loading || loadingTable) && (
                  <>
                    <Box mb={0.5}>
                      <Skeleton
                        variant="rect"
                        width="100%"
                        className={classes.skeleton}
                        height="44px"
                      />
                    </Box>
                    <Box mb={0.5}>
                      <Skeleton
                        variant="rect"
                        width="100%"
                        className={classes.skeleton}
                        height="60px"
                      />
                    </Box>
                    <Box mb={0.5}>
                      <Skeleton
                        variant="rect"
                        width="100%"
                        className={classes.skeleton}
                        height="60px"
                      />
                    </Box>
                    <Box mb={0.5}>
                      <Skeleton
                        variant="rect"
                        width="100%"
                        className={classes.skeleton}
                        height="60px"
                      />
                    </Box>
                  </>
                )}
              </Box>
            </Paper>
            <Pagination
              onPageChange={changePagination}
              initialPage={pagination.currentPage}
              numberOfPages={pagination.numberOfPages}
              setCurrentPage={setCurrentPage}
            />
          </>
        )}
      </Content>
    </Template>
  );
}

TrackingContainer.propTypes = {
  withLargeUnitload: PropTypes.bool
};

TrackingContainer.defaultProps = {
  withLargeUnitload: false
};
