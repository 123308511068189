export const TEXT = {
  TITLE: 'Agrupar pacotes',
  SUBTITLE: 'Bipe ou digite o código dos pacotes que vão ser agrupados.',
  EMPTY_LIST: 'Sua lista ainda está vazia',
  INSTRUCTIONS:
    'Assim que você bipar os pacotes, eles vão aparecer aqui para serem agrupados.',
  PLACEHOLDER: 'Bipe ou digite o código',
  GROUP_PACKAGES: 'Agrupar pacotes',
  BODY_TITLE: 'Lista',
  DELIVERY: 'Entregar',
  WAIT: 'Aguardar',
  DEADLINE: 'até',
  AWAITING_RESOLUTION_ALERT: 'Esse pacote ainda está no prazo de tratativa.',
  SUCCESS_MESSAGE: 'Tudo certo, pacotes agrupados!',
  COUNT_PACKAGES: 'pacotes',
  COUNT_PACKAGE: 'pacote',
  DELIVERED_ALERT: barcode =>
    `O status do pacote ${barcode} não permite a separação. Entre em contato com a Central de Ajuda`,
  CLOSE_EDIT_TITLE: 'Você quer cancelar a edição do grupo?',
  CLOSE_EDIT_DESCRIPTION: 'Ao voltar, as edições realizadas não vão ser salvas',
  CLOSE_CONFIRMATION: 'Quero cancelar',
  CLOSE_CANCEL: 'Continuar edição',
  SAVE: 'Salvar',
  ADDED_PACKAGES: n => `${n} Pacotes adicionados`,
  REGIONS: regions => {
    switch (regions.length) {
      case 0:
        return '';
      case 1:
        return `Para ${regions[0]}`;
      case 2:
        return `Para ${regions[0]} e ${regions[1]}`;
      default:
        return `Para ${regions[0]}, ${regions[1]} e mais ${regions.length - 2}`;
    }
  },
  KEYWORDS: 'Palavras-chave',
  KEYWORDS_SUBTITLE: 'Cadastre palavras para ajudar a identificar o grupo',
  KEYWORDS_CAPTION: 'Adicione até 2 palavras (até 16 caracteres cada)',
  KEYWORDS_PLACEHOLDER: 'Digite e tecle enter para adicionar',
  CONTINUE: 'Continuar',
  OPERATOR_NOT_IN_CIRCLE:
    'Você não está em um círculo. ' +
    'Para criar grupos é necessário fazer ' +
    'parte de um círculo. ' +
    'Solicite ao Leve a inclusão do seu usuário'
};

export const HEADER_TEXT = {
  title: TEXT.TITLE,
  subtitle: TEXT.SUBTITLE,
  placeholder: TEXT.PLACEHOLDER,
  added_packages_subtitle: TEXT.ADDED_PACKAGES,
  regions: TEXT.REGIONS
};

export const CONTENT_TEXT = {
  empty_list: TEXT.EMPTY_LIST,
  instructions: TEXT.INSTRUCTIONS,
  title: TEXT.BODY_TITLE,
  delivery_text: TEXT.DELIVERY,
  wait_text: TEXT.WAIT,
  deadline: TEXT.DEADLINE,
  group_packages: TEXT.GROUP_PACKAGES,
  count_packages: TEXT.COUNT_PACKAGES,
  count_package: TEXT.COUNT_PACKAGE,
  keywords: TEXT.KEYWORDS,
  keywords_subtitle: TEXT.KEYWORDS_SUBTITLE,
  keywords_caption: TEXT.KEYWORDS_CAPTION,
  keywords_placeholder: TEXT.KEYWORDS_PLACEHOLDER,
  continue: TEXT.CONTINUE,
  save: TEXT.SAVE
};

export const CLOSE_MODAL_TEXT = {
  title: TEXT.CLOSE_EDIT_TITLE,
  description: TEXT.CLOSE_EDIT_DESCRIPTION,
  confirmation: TEXT.CLOSE_CONFIRMATION,
  cancel: TEXT.CLOSE_CANCEL
};

export const VIEWS_DRAWER = {
  PACKAGES: 'PACKAGES',
  KEYWORDS: 'KEYWORDS'
};

export const DISTRIBUTE_CONTEXT = 'DIS';
export const SEPARATION_CONTEXT = 'SEP';
