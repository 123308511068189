import React from 'react';
import ConfirmDialogComponent from 'shared/confirm-dialog/confirm-dialog.component';
import PropTypes from 'prop-types';
import { interpret, Interpreter } from 'xstate';
import { useHistory } from 'react-router-dom';
import { Button, Box, CircularProgress } from '@material-ui/core';
import { useService } from '@xstate/react';
import { AUTHORIZED_ROUTES } from 'view/constants';
import { getSelectedRoutingCode } from 'auth/login/login.service';
import TEXT from './drawer-return-package-to-base.constants';
import returnPackageToBaseMachine, {
  STATES,
  ACTIONS
} from './drawer-return-package-to-base.machine';
import drawerMachine from '../drawer.machine';

export default function DrawerReturnPackageToBase({
  returnPackageToBaseMachineRef
}) {
  const [current, send] = useService(returnPackageToBaseMachineRef);
  const history = useHistory();

  const historyPush = path => {
    const rc = getSelectedRoutingCode();
    history.push(`/${rc}${path}`);
    send(ACTIONS.RELOAD_PAGE);
  };

  const reloadPageConfirm = () => {
    send(ACTIONS.RELOAD_PAGE);
  };

  const isFlowConfirmDialog = current.value === STATES.CONFIRM_DIALOG;

  return (
    <>
      {isFlowConfirmDialog && (
        <>
          <ConfirmDialogComponent
            open
            title={TEXT.DIALOG_CONFIRM_TITLE}
            description={TEXT.DIALOG_CONFIRM_DESCRIBE}
          >
            <Button
              fullWidth
              color="primary"
              variant="outlined"
              size="large"
              onClick={() => historyPush(AUTHORIZED_ROUTES.TRACKING.IN_BASE)}
            >
              {TEXT.DIALOG_CONFIRM_TEXT_BTN_LEFT}
            </Button>
            <Button
              fullWidth
              variant="outlined"
              color="primary"
              size="large"
              onClick={reloadPageConfirm}
            >
              {TEXT.DIALOG_CONFIRM_TEXT_BTN_RIGHT}
            </Button>
          </ConfirmDialogComponent>
          <Box display="flex" justifyContent="center" alignItems="center">
            <CircularProgress
              justify="center"
              variant="determinate"
              value={95}
            />
          </Box>
        </>
      )}
      {!isFlowConfirmDialog && (
        <Box display="flex" justifyContent="center" alignItems="center">
          <CircularProgress justify="center" />
        </Box>
      )}
    </>
  );
}

// this function is use at storybook and tests only
export const makeReturnPackageToBaseMachineRef = (context = {}) => {
  const service = interpret(returnPackageToBaseMachine, {
    context: returnPackageToBaseMachine.withContext({
      ...returnPackageToBaseMachine.context,
      packageBarcode: context.packageBarcode,
      ...context
    }),
    parent: interpret(drawerMachine).start()
  });
  service.start();
  return service;
};

DrawerReturnPackageToBase.propTypes = {
  returnPackageToBaseMachineRef: PropTypes.instanceOf(Interpreter).isRequired
};
