/* eslint-disable camelcase */
import * as loginService from 'infra/service/login';

import { getSelectedBase } from 'profile/profile.service';

export const CUSTOM_MESSAGES = {
  401: 'Você digitou o e-mail ou a senha errada. Tente de novo.',
  400: 'Você digitou o e-mail ou a senha errada. Tente de novo.'
};
export const VALIDATION_ERROR_MESSAGE = 'Digite um e-mail válido.';

export const isAuthenticated = () => loginService.isAuthenticated();

export const getLoggedUser = () => loginService.getLoggedUser();

export const getUser = () => loginService.getUser();

export const logout = () => loginService.logout();

export const getLoggedDCId = () =>
  loginService.getLoggedDCId() || getSelectedBase()?.distribution_center?.id;

export const getLoggedLMCId = () =>
  loginService.getLoggedLMCId() ||
  getSelectedBase()?.last_mile_company?.identification;

export const getLoggedLMCCompanyType = () =>
  getSelectedBase()?.last_mile_company?.company_type;

export const getSelectedRoutingCode = () => {
  const DCRoutingCode = getSelectedBase()?.distribution_center?.routing_code;
  const LMCIdentification = getSelectedBase()?.last_mile_company
    ?.identification;

  return DCRoutingCode || LMCIdentification;
};
