import * as loginService from 'infra/service/login';

export const CUSTOM_MESSAGES = {
  401: 'Você digitou o e-mail ou a senha errada. Tente de novo.'
};
export const VALIDATION_ERROR_MESSAGE = 'Digite um e-mail válido.';

export const isAuthenticated = () => loginService.isAuthenticated();

export const getLoggedUser = () => loginService.getLoggedUser();

export const logout = () => loginService.logout();
