import movePackageListService from 'infra/service/move-package-list';
import db from 'infra/storage/async-elasticsearch-database';
import { packageCacheModel } from 'infra/storage/async-elasticsearch-database.model';
import {
  getLoggedDCId,
  getLoggedLMCId,
  getSelectedRoutingCode
} from 'auth/login/login.service';

import { STATUS_ERROR_CODE } from 'information/drawer-packages-out-of-coverage/drawer-packages-out-of-coverage.constants';

import { translateStatusCode, DEFAULT_MESSAGE } from '../httpMessage';

export const CUSTOM_ERROR_MESSAGES = {
  404: 'Não conseguimos encontrar o pacote. Tente de novo daqui a pouco ou atualize a página.',
  406: 'Opa, esse entregador não está habilitado, verifique o cadastro ou escolha outra pessoa.',
  412: 'Esse pacote já foi entregue, por isso, não é possível fazer alterações.',
  417: 'Os pacotes só podem ser recusados no momento do recebimento.',
  424: 'O status atual do pacote {} não permite a distribuição. Faça devolução para Loggi.',
  425: barcodes =>
    barcodes.length !== 0
      ? `Os seguintes pacotes estão fora da área de cobertura: ${barcodes.join(
          ', '
        )}.`
      : 'O grupo possui pacotes fora da área de cobertura.',
  428: 'Esse pacote não foi recebido na plataforma. Bipe para receber antes de distribuir.',
  429: 'Aguarde, esta mudança já está sendo processada'
};

const STATUS_ERROR_CODES = {
  INVALID_STATUS_FOR_DELIVERY: 424
};

const movePackageList = (
  process,
  destination,
  packagesInfo,
  isPhyisicalEvidence,
  latitude,
  longitude,
  statusCodeOverwrite = null,
  userId = null
) =>
  movePackageListService(
    {
      process,
      destination,
      distributionCenterId: getLoggedDCId(),
      lastMileCompanyId: getLoggedLMCId(),
      userId,
      distributionCenterRoutingCode: getSelectedRoutingCode()
    },
    packagesInfo,
    isPhyisicalEvidence,
    latitude,
    longitude,
    statusCodeOverwrite
  )
    .then(response => {
      db.savePackages(
        response.data.successPackages.map(pkg =>
          packageCacheModel(
            pkg.packageId,
            pkg.sortingRecordId,
            pkg.licensePlate || ''
          )
        )
      );
      return {
        success: response.data.successPackages,
        failed: response.data.failedPackages
      };
    })
    .catch(error => {
      if (
        error?.response?.status === STATUS_ERROR_CODE.PACKAGES_OUT_OF_COVERAGE
      ) {
        const errorResponse = error.response;
        errorResponse.message = CUSTOM_ERROR_MESSAGES[
          STATUS_ERROR_CODE.PACKAGES_OUT_OF_COVERAGE
        ](
          errorResponse.data.flatMap(p =>
            p.barcode && p.barcode !== 'None' ? [p.barcode] : []
          )
        );
        throw errorResponse;
      }

      if (
        error?.response?.status ===
        STATUS_ERROR_CODES.INVALID_STATUS_FOR_DELIVERY
      ) {
        const barcode = error.response.data.split(':')[1].trim();
        const responseError = {
          message: CUSTOM_ERROR_MESSAGES[error.response.status].replace(
            '{}',
            barcode
          )
        };
        throw responseError;
      }

      const responseError = {
        message: error.response
          ? translateStatusCode(error.response.status, CUSTOM_ERROR_MESSAGES)
          : DEFAULT_MESSAGE
      };
      throw responseError;
    });

export default movePackageList;
