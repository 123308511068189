import { RESPONSE_MESSAGES_BY_CODE } from 'app/httpMessage';
import { translateStatusCode, DEFAULT_MESSAGE } from '../httpMessage';

export const handleResponseError = error => {
  const errorMessage = {
    code: error.response ? error.response.status : null,
    message: error.response
      ? translateStatusCode(error.response.status, RESPONSE_MESSAGES_BY_CODE)
      : DEFAULT_MESSAGE
  };
  return errorMessage;
};

export default handleResponseError;
