import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  CircularProgress,
  Typography,
  TextField,
  Button
} from '@material-ui/core';
import { colors } from '@loggi/mar';
import { Can } from 'auth/access-control';
import { ACTIONS } from 'auth/access-control/access-control.rules';
import { DrawerContent, DrawerHeader, CustomDrawer } from 'shared/drawer';
import { ReactComponent as BipPackagesIcon } from 'images/bip-packages.svg';
import {
  HEADER_TEXT,
  CONTENT_TEXT,
  VIEWS_DRAWER
} from './drawer-group-packages.constants';
import { useStyles } from './drawer-group-packages.styles';
import CardPackageInfoContainer from './card-package-info';

export default function DrawerGroupPackages({
  newPackagesInfo,
  setNewPackagesInfo,
  loading,
  setLoading,
  loadingEditData,
  onRead,
  currentPackagesInfo,
  setOpenCloseConfirmationDialog,
  setShowViewDrawer,
  movePackagesToGroup
}) {
  const [barcode, setBarcode] = useState('');
  const [scanned, setScanned] = useState(false);
  const [isMobile] = useState(window.navigator.userAgent.includes('Mobile'));
  const classes = useStyles();

  const _onRead = event => {
    event.preventDefault();
    if (barcode === '') {
      return;
    }
    onRead(barcode, isMobile ? scanned : false);
    setBarcode('');
    setScanned(false);
  };

  const allPackagesToList = [
    ...Object.values(newPackagesInfo),
    ...Object.values(currentPackagesInfo)
  ];

  return (
    <Box
      bgcolor="rgba(0,0,0,0.5)"
      zIndex="1000"
      position="fixed"
      left={0}
      right={0}
      top={0}
      bottom={0}
      data-testid="drawer-component"
    >
      <CustomDrawer>
        <DrawerHeader
          title={HEADER_TEXT.title}
          handleClosingDrawer={() => setOpenCloseConfirmationDialog(true)}
          showDivider={allPackagesToList.length > 0}
        >
          <Box pt={0.5}>
            <Typography variant="subtitle1">{HEADER_TEXT.subtitle}</Typography>
          </Box>
          <Box pt={5}>
            <form onSubmit={_onRead} data-testid="drawer-component-form">
              <Can
                actions={[
                  ACTIONS.TO_TRANSFER_READ,
                  ACTIONS.CIRCLES_WRITE_FOR_OPERATOR
                ]}
              >
                <TextField
                  variant="outlined"
                  placeholder={HEADER_TEXT.placeholder}
                  required
                  fullWidth
                  onChange={event => setBarcode(event.target.value)}
                  value={barcode}
                  inputProps={{ minLength: 3, className: classes.input }}
                  onKeyPress={event => {
                    if (event.key === 'Enter') {
                      return;
                    }
                    setScanned(true);
                    setBarcode(event.target.value);
                  }}
                  autoFocus
                />
              </Can>
            </form>
          </Box>
        </DrawerHeader>
        <DrawerContent>
          <Box display="flex" flexDirection="column" mb={9.5}>
            {loading && (
              <Box
                pt={5}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <CircularProgress />
              </Box>
            )}
            {allPackagesToList?.length > 0 && !loading && (
              <Box pb={2}>
                <Typography variant="subtitle1">
                  <strong>{CONTENT_TEXT.title}</strong>
                </Typography>
                <Typography variant="h4">
                  {allPackagesToList.length}{' '}
                  {allPackagesToList.length === 1
                    ? CONTENT_TEXT.count_package
                    : CONTENT_TEXT.count_packages}
                </Typography>
              </Box>
            )}
            {allPackagesToList?.length > 0 && !loadingEditData && (
              <>
                {loadingEditData ? (
                  <Box
                    pt={5}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <CircularProgress />
                  </Box>
                ) : (
                  allPackagesToList.map(pkg => (
                    <CardPackageInfoContainer
                      packageId={pkg.packageId}
                      key={pkg.packageId}
                      newPackagesInfo={newPackagesInfo}
                      setNewPackagesInfo={setNewPackagesInfo}
                      loading={loading}
                      setLoading={setLoading}
                      currentPackageInfo={currentPackagesInfo?.[pkg.packageId]}
                    />
                  ))
                )}
              </>
            )}
            {allPackagesToList?.length === 0 && !loading && !loadingEditData && (
              <>
                <Box
                  height={124}
                  width={118}
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                >
                  <BipPackagesIcon />
                </Box>
                <Box pt={3.5}>
                  <Typography variant="subtitle1">
                    <strong>{CONTENT_TEXT.empty_list}</strong>
                  </Typography>
                </Box>
                <Box pt={2.5} color={colors.smoke[700]}>
                  <Typography variant="subtitle2">
                    {CONTENT_TEXT.instructions}
                  </Typography>
                </Box>
              </>
            )}
            {(loading || loadingEditData) && (
              <Box
                pt={5}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <CircularProgress />
              </Box>
            )}
          </Box>
        </DrawerContent>
        <DrawerContent>
          <Box
            bottom={0}
            display="flex"
            justifyContent="center"
            padding="40px 5px"
            position="fixed"
            width="424px"
            height="auto"
            bgcolor="white"
          >
            {Object.keys(currentPackagesInfo).length === 0 ? (
              <Button
                fullWidth
                size="large"
                variant="outlined"
                color="primary"
                disabled={Object.keys(newPackagesInfo).length === 0 || loading}
                data-testid="btn-set-keywords"
                onClick={() => setShowViewDrawer(VIEWS_DRAWER.KEYWORDS)}
              >
                {CONTENT_TEXT.continue}
              </Button>
            ) : (
              <Button
                fullWidth
                size="large"
                variant="contained"
                color="primary"
                disabled={newPackagesInfo.length === 0 || loading}
                data-testid="btn-move-packages-to-group"
                onClick={() => movePackagesToGroup(newPackagesInfo)}
              >
                {CONTENT_TEXT.group_packages}
              </Button>
            )}
          </Box>
        </DrawerContent>
      </CustomDrawer>
    </Box>
  );
}

DrawerGroupPackages.propTypes = {
  newPackagesInfo: PropTypes.objectOf(
    PropTypes.shape({
      packageId: PropTypes.string,
      barcode: PropTypes.string
    })
  ).isRequired,
  setNewPackagesInfo: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  setLoading: PropTypes.func.isRequired,
  loadingEditData: PropTypes.bool.isRequired,
  onRead: PropTypes.func.isRequired,
  currentPackagesInfo: PropTypes.shape(),
  setOpenCloseConfirmationDialog: PropTypes.func.isRequired,
  setShowViewDrawer: PropTypes.func.isRequired,
  movePackagesToGroup: PropTypes.func.isRequired
};

DrawerGroupPackages.defaultProps = {
  currentPackagesInfo: {}
};
