const TEXT = {
  DIALOG_CONFIRM_TITLE: 'Pacote na base',
  DIALOG_CONFIRM_DESCRIBE: 'Pronto, tudo certo! O pacote foi para na base.',
  DIALOG_CONFIRM_TEXT_BTN_LEFT: 'Ir para na base',
  DIALOG_CONFIRM_TEXT_BTN_RIGHT: 'Beleza, entendi'
};

export const URL_GTM_GA_RETURN_TO_BASE_DONE = '/enviar-pra-base/sucesso';

export default TEXT;
