/* eslint-disable camelcase */
import { removeSelectedBase } from 'profile/profile.service';

const CREDENTIAL_KEY = 'currentUser';

export const getCredentials = () =>
  JSON.parse(localStorage.getItem(CREDENTIAL_KEY)) || {};

export const storeCredentials = credential => {
  localStorage.setItem(CREDENTIAL_KEY, JSON.stringify(credential));
};

export const removeCredentials = () => {
  localStorage.removeItem(CREDENTIAL_KEY);
  removeSelectedBase();
};

export const updateCredentials = newCredentials => {
  const credentials = getCredentials();
  const {
    access_token,
    refresh_token,
    token_type,
    expires_in
  } = newCredentials;
  credentials.access_token = access_token;
  credentials.refresh_token = refresh_token;
  credentials.token_type = token_type;
  credentials.expires_in = expires_in;
  localStorage.setItem(CREDENTIAL_KEY, JSON.stringify(credentials));
};
