import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useAmplifyAuth } from '@loggi/authentication-lib';
import { logout } from 'auth/login/login.service';
import ProfileInital from './profile-initial.component';

const ProfileInitialContainer = ({
  setProfileState,
  userName,
  dcAbbrev,
  dcName,
  hasMoreMultipleChoices
}) => {
  const history = useHistory();
  const historyPush = path => {
    history.push(path);
  };

  const { signOut } = useAmplifyAuth();

  return (
    <ProfileInital
      dcAbbrev={dcAbbrev}
      dcName={dcName}
      userName={userName}
      setProfileState={setProfileState}
      hasMoreMultipleChoices={hasMoreMultipleChoices}
      handleLogout={() => {
        signOut();
        logout();
        historyPush('/');
      }}
    />
  );
};

export default ProfileInitialContainer;

ProfileInitialContainer.propTypes = {
  setProfileState: PropTypes.func.isRequired,
  userName: PropTypes.string,
  dcAbbrev: PropTypes.string,
  dcName: PropTypes.string,
  hasMoreMultipleChoices: PropTypes.bool.isRequired
};
ProfileInitialContainer.defaultProps = {
  userName: '',
  dcAbbrev: '',
  dcName: ''
};
